import { toast } from "react-toastify";
import ScanResults from "./ScanResults";
import "../../../assets/css/common.css";
import Diagnostic from "./Cards/Diagnostic";
import Calibration from "./Cards/Calibration";
import { CustomDialog } from "react-st-modal";
import { useNavigate } from "react-router-dom";
import SolventReference from "./Cards/SolventReference";
import React, {useState, useEffect, useCallback} from "react";
import SelectYourHardware from "./Cards/SelectYourHardware";
import { CButton, CCol, CContainer, CRow } from "@coreui/react";
import jwtInterceoptor from "../../../views/shared/jwtInterceptor";
import { setCookieItemWithExpiry, isMoreThanOneDay } from "../../../views/shared/utils";

type StateObjectTypes = {
	dated: string;
	old: boolean;
};

const initializeStateWithLocalStorageDataAndCheckExpiryDate = (key: string): object => {
	const defaultState = { old: true, dated: "--/--/--" };
	const storedData = localStorage.getItem(key);
	if (storedData) {
		const parsedData = JSON.parse(storedData);
		return { ...parsedData, old: isMoreThanOneDay(parsedData?.dated) };
	}
	return defaultState;
};

const SystemControlCenter = () => {

	const navigate = useNavigate();
	const [latestDiagnostic, setLatestDiagnostic] = useState<StateObjectTypes>(() => initializeStateWithLocalStorageDataAndCheckExpiryDate("latestDiagnostic") as StateObjectTypes);
	const [latestUnitCalibration, setLatestUnitCalibration] = useState<StateObjectTypes>(() => initializeStateWithLocalStorageDataAndCheckExpiryDate("latestUnitCalibration") as StateObjectTypes);
	const [latestSolventReference, setLatestSolventReference] = useState<StateObjectTypes>(() => initializeStateWithLocalStorageDataAndCheckExpiryDate("latestSolventReference") as StateObjectTypes);



	// Function to handle hardware scanning
	const handleScanHardware = async () => {
		let toastId = null;
		try {
			const shouldTakeNewSolventReference = await CustomDialog(
				<ScanResults
					question={"Is the pipetrain CLEAN and FULL of solvent?"}
					yesOption={"Yes, take a new Solvent Reference"}
                    centerYesButton={true}
					noOption={null}
				/>,
				{
					title: "Scan",
				}
			);

			if (shouldTakeNewSolventReference) {
				toastId = toast.loading("Taking a New Solvent Reference...");
				const response = await jwtInterceoptor.post(
					`${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=scan_hardware&scan_type=solvent`);
				let latestSolventReference = JSON.stringify({
					...response.data,
					dated: new Date(),
				});
				localStorage.setItem("latestSolventReference", latestSolventReference);
				setLatestSolventReference({dated: new Date() + '', old: false});

				// Store the scan ID in local storage with an expiry time
				setCookieItemWithExpiry("solvent_scan_id", response.data.scan_id);

				toast.update(toastId, {
					render: "Successfully took a new Solvent Reference",
					type: "success",
					isLoading: false,
					autoClose: 2000
				});
			}
		} catch (error) {
			localStorage.removeItem("latestSolventReference");
			if (toastId) {
				toast.update(toastId, {
					render: "Error taking a new Solvent Reference",
					type: "error",
					isLoading: false,
					autoClose: 2000
				});
			}
		}
	};

	const fetchData = async () => {
		const html = "Is the pipetrain CLEAR of solvent and sample?";
		const result = await CustomDialog(
			<ScanResults
				yesOption={"Yes, run a new Diagnostic Scan."}
				noOption={null}
				centerYesButton={true}
				question={html}
			/>,
			{
				title: "Scan",
			}
		);
		if (!result) {
			return
		}
		let toastId = toast.loading("Running System Diagnostic...");
		try {
			const lampResponse = await jwtInterceoptor.post(
				`${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=diagnostic`,
				"diagnostic_type=lamp",
				{headers: {"Content-Type": "application/x-www-form-urlencoded"}}
			);
			if (lampResponse.status === 201) {
				const failedTests = Object.keys(lampResponse.data).filter(
					(lampKey) => lampResponse.data[lampKey].test_result === "Fail"
				);

				if (failedTests.length > 0) {
					toast.update(toastId, {
						render: "Please Clean the Flow Cell",
						type: "error",
						isLoading: false,
						autoClose: 2000
					});
				} else {
					let latestDiagnostic = JSON.stringify({dated: new Date()});
					localStorage.setItem("latestDiagnostic", latestDiagnostic);
					setLatestDiagnostic({dated: new Date() + '', old: false});
					toast.update(toastId, {
						render: "Diagnostics Ran Successfully",
						type: "success",
						isLoading: false,
						autoClose: 2000
					});
				}
			}
		} catch (error) {
			toast.update(toastId, {
				render: "Error Running the Diagnostics",
				type: "error",
				isLoading: false,
				autoClose: 2000
			});
		}
	};

	const handleYesClick = () => {
		fetchData();
	};

	const fetchDevices = async () => {
		const user_connection = await jwtInterceoptor.get(
			`${process.env.REACT_APP_API_URL}/user/api/user-connection/`
		);
		return user_connection?.data?.results;
	};

	const [connections, setConnection] = useState([]);
	const [selectedConnection, setSelectedConnection] = useState(() => {
		const storedConnection = localStorage.getItem("selectedHardware");
		return storedConnection ? JSON.parse(storedConnection) : null;
	});

	const [deviceStatus, setDeviceStatus] = useState(false);
	useEffect(() => {
		fetchDevices().then((data) => {
			if (data) {
				const currentUrl = window.location.href;
				if (currentUrl.includes('demo')) {
					const filteredData = data.filter(
						(device: { machine_name: string; }) => device.machine_name.toLowerCase() === 'dummy'
					);
					setConnection(filteredData);
				}else{
					setConnection(data);
				}
			}
		});
		//cleanup
		return () => {
			setConnection([]);
		};
	}, []);


	const checkDeviceStatus = useCallback(async (id: number) => {

		let deviceStatus;
		let toastId;
		toastId = toast.loading("Checking Hardware Status");
		try {
			const user_connection = await jwtInterceoptor.get(
				`${process.env.REACT_APP_API_URL}/user/api/user-connection/${id}/`
			);
			let response = user_connection.data;
			deviceStatus = response.status_active;
			setDeviceStatus(deviceStatus);
			toast.update(toastId, {
				render: `${response.machine_name} Connected`,
				type: toast.TYPE.SUCCESS,
				isLoading: false,
				autoClose: 2000,
			});
		} catch (error) {

			toast.update(toastId, {
				render: "Failed to check hardware status",
				type: toast.TYPE.ERROR,
				isLoading: false,
				autoClose: 2000,
			});
		}
	}, [setDeviceStatus]);

	const handleConnections = useCallback(() => {
		if (!selectedConnection) {
			toast.error("Select your Hardware");
			return;
		}
		checkDeviceStatus(selectedConnection.id);
	}, [selectedConnection, checkDeviceStatus]);

	useEffect(() => {
		if (selectedConnection) {
			handleConnections();
			localStorage.setItem(
				"selectedHardware",
				JSON.stringify({...selectedConnection, dated: new Date()})
			);
		}
	}, [handleConnections, selectedConnection]);

	const handleUnitCalibration = async () => {
		var toastId = toast.loading("Calibrating...");
		try {
			const calibration = await jwtInterceoptor.post(
				`${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=calibrate`
			);
			let calibrationFormattedDate = JSON.stringify({dated: new Date()});
			if (calibration?.status === 201) {
				toast.update(toastId, {
					render: "Successfully Calibrated",
					type: "success",
					isLoading: false,
					autoClose: 2000
				});
				localStorage.setItem("latestUnitCalibration", calibrationFormattedDate);
				calibrationFormattedDate = JSON.parse(calibrationFormattedDate);
				setLatestUnitCalibration({
					dated: new Date() + '',
					old: isMoreThanOneDay(new Date()),
				});
			}
		} catch (err) {
			toast.update(toastId, {
				render: "Error Calibrating Spectrometer",
				type: "error",
				isLoading: false,
				autoClose: 2000
			});
		}
	};

	return (

		<CContainer>
			<h1
				style={{
					fontWeight: 700,
					fontSize: "25px",
					lineHeight: "43.57px",
					color: "#000000",
					marginBottom: "20px",
					textAlign: "center",
					fontFamily: "Expansiva, sans-serif",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				System Control Center
			</h1>

			<CContainer className="container">
				<CRow>
					<CCol
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "center",
							marginBottom: "2rem",
						}}
					>
						<SelectYourHardware
							selectedConnection={selectedConnection}
							setSelectedConnection={setSelectedConnection}
							connections={connections}
							deviceStatus={deviceStatus}
							handleConnections={handleConnections} />
					</CCol>
					<CCol
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "center",
							marginBottom: "2rem",
						}}
					>
						<Diagnostic latestDiagnostic={latestDiagnostic} handleYesClick={handleYesClick} />
					</CCol>
					<CCol
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "center",
							marginBottom: "2rem",
						}}
					>
						<Calibration latestUnitCalibration={latestUnitCalibration} handleUnitCalibration={handleUnitCalibration} />
					</CCol>
					<CCol
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "center",
							marginBottom: "2rem",
						}}
					>
					<SolventReference latestSolventReference={latestSolventReference} handleScanHardware={handleScanHardware} />
					</CCol>
				</CRow>
			</CContainer>
			<CRow>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CButton
						variant="ghost"
						type="submit"
						style={{
							backgroundColor:
								!latestUnitCalibration?.old &&
								!latestDiagnostic?.old &&
								!latestSolventReference?.old
									? "#3AC90A"
									: "#D8D8D8",
							color: "#000000",
							fontWeight: "600",
							height: "45px",
							fontSize: "14px",
							fontFamily: "Inter",
							width: "30%",
							marginTop: "20px",
						}}
						onClick={() => navigate("/ExtractoPredictionDashboard")}
					>
						Start Measuring Sample
					</CButton>
				</div>
			</CRow>
		</CContainer>
	);
};

export default SystemControlCenter;
