import React, { useContext } from "react";
import { CContainer, CHeader, CHeaderNav } from "@coreui/react";
import "../assets/css/common.css";
import AppHeaderDropdown from "./header/AppHeaderDropdown";
import AuthContext from "./shared/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";

const AppHeader = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  if (!authContext) {
    return <Navigate to="/" />;
  }

  const { logout } = authContext;

  return (
    <CHeader
      // position="sticky"
      style={{ backgroundColor: "transparent" }}
      className="mb-4 "
    >
      <CContainer fluid>
        <div
          onClick={() => navigate("/Home")}
          className=""
          style={{ fontFamily: "Expansiva, sans-serif" }}
        >
          <div className="justified-container ">
            <div className="justified-line ">
              <h2 style={{ fontSize: "18px" }}>Newton</h2>
            </div>
            <div className="justified-line">
              <p style={{ marginBottom: "0px", fontSize: "12px" }}>Insights</p>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <CHeaderNav className="user-name">
            <AppHeaderDropdown logout={logout} />
          </CHeaderNav>
        </div>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
